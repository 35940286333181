<template>
    <div class="md_main">
        <div class="md_top">
            <div class="md_top_son">
                <el-breadcrumb separator="/" style="padding-top: 13px">
                    <el-breadcrumb-item><router-link :to="{path:'/'}">首页</router-link></el-breadcrumb-item>
                    <el-breadcrumb-item>解决方案</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="mdm_son">
            <div class="mdm_l" :style="'background-image:url('+imagePathP+');'">
<!--          <div class="mdm_l" >-->
<!--                <img :src=" imagePathP">-->
            </div>
            <div class="mdm_r">
                <div class="mdr_1">商品详情</div>
                <div class="mdr_2">
                    {{describe}}
                </div>

                <div class="pdr_3">
                    <span>价格</span>
                    <div class="price">
                        ¥{{pri}}
                    </div>
                </div>
                <div class="pdr_3">
                    <span>{{title}}</span>
                    <div>
                        <el-select style="margin-right: 10px" filterable  @change="gl1Change" class="oi" v-model="num" >
                            <el-option v-for="item in proList" :label="item.moduleNumber" :value="item.moduleNumber" ></el-option>
                        </el-select>
                        {{isC}}
                    </div>
                </div>
                <div class="mdr_5">
                    <span>数量</span>

                        <el-input style="width: 227px" v-model="counts" type="number" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </div>
                <el-button  type="danger" class="mdr_6" @click="openDia" :loading="btnLoading">生成清单</el-button>
                <el-button  type="danger" class="mdr_6" @click="addCar" :loading="btnLoading">加入购物车</el-button>

            </div>
        </div>

        <el-dialog title="配置单名称填写" :visible.sync="dialogVisible" width="370px">
            <el-form  label-width="100px">
                <el-form-item v-show="addShow"><el-button @click="openAddShow">新建项目</el-button></el-form-item>
                <el-form-item v-show="!addShow"><el-button @click="closeAddShow">返回</el-button></el-form-item>
                <el-form-item label="已有项目" v-show="addShow">
                    <el-select v-model="xmList" placeholder="请选择项目" >
                        <el-option
                                v-for="item in listChange"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="项目名称" v-show="!addShow">
                    <el-input v-model="input1" placeholder="请输入项目名称"></el-input>
                </el-form-item>
                <el-form-item label="配置单名称" >
                    <el-input v-model="input" placeholder="请输入配置单名称"></el-input>
                </el-form-item>
                <el-form-item label="套数" >
                    <el-input v-model="inputTs" placeholder="请输入套数" type="number" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="savePLFin" :loading="btnLoading" v-show="addShow">确 定</el-button>
                <el-button type="primary" @click="saveAddPLFin" :loading="btnLoading" v-show="!addShow">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    import {
        getProDetial,
        addBuyCar,
        projectList,
        getConfigPLFin,
        projectAddNew,
        addMiniDate,
        addSingleList,
    } from "@/Api/finance/home";
    import { encrypt,decrypt } from "@/Util/auth";
    export default {
        name: "index",
        data() {
            return {
                addShow:true,
                btnLoading:false,
                inputTs:'1',
                imagePathP:'',
                describe:'',
                pri:'',
                num:'',
                title:'',
                isC:'',
                proList:[],
                xmList:'',
                code:'',
                counts:'1',
                input1:'',
                input:'',
                isEdit:true,
                dialogVisible:false,
            }
        },
        mounted() {
            this.getList()
            this.getprojectList()
        },
        methods: {
            saveAddPLFin(){

                this.btnLoading = true;
                let obj = {
                    name: this.input1,
                };
                let ss= encrypt(JSON.stringify(obj))
                projectAddNew(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        let parmars2={
                            "projId": dd.data,
                            "name": this.input1,
                            "code": this.code,
                            "counts": this.counts,
                            sets:this.inputTs
                        }
                        let parmarsCode2= encrypt(JSON.stringify(parmars2))
                        addSingleList(parmarsCode2).then(ress => {
                            let resCoDE2= JSON.parse(decrypt(ress))
                            console.log(resCoDE2,'resCoDE')
                            if (resCoDE2.code == 200) {
                                this.btnLoading = false;
                                this.$router.push({name:'productList',query: {type:'0',id:dd.data, name: this.input1}})
                            }else {
                                this.btnLoading = false;
                                alert(resCoDE2.msg);
                            }

                        })
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            savePLFin(){

                this.btnLoading = true;
                let parmars={
                    "projId": this.xmList,

                    "name": this.input,

                    "code": this.code,

                    "counts": this.counts,
                    sets:this.inputTs
                }
                let names= ''
                this.listChange.find(item=>{
                    if(item.id==this.xmList){
                        names=item.name
                    }
                })
                let parmarsCode= encrypt(JSON.stringify(parmars))
                addSingleList(parmarsCode).then(res => {
                    let resCoDE= JSON.parse(decrypt(res))
                    console.log(resCoDE,'resCoDE')
                    if (resCoDE.code == 200) {
                        this.btnLoading = false;
                        this.$router.push({name:'productList',query: {type:'0',id:this.xmList,name:names}})
                    }else {
                        this.btnLoading = false;
                        alert(resCoDE.msg);
                    }

                })
            },
            getprojectList(){

                let obj = {
                    page: '1',
                    size: '99999'
                };
                let ss= encrypt(JSON.stringify(obj))
                projectList(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.listChange=dd.data.records
                    }else {
                        alert(dd.msg);
                    }

                })

            },
            openAddShow(){
                this.addShow=false
            },
            closeAddShow(){
                this.addShow=true
            },
            openDia(){
                if(this.num ==''){
                    this.$message.error(`请选择商品`);
                }else {
                    this.dialogVisible=true
                }
            },
            gl1Change(value){
              let ss= this.proList.filter(item=> item.moduleNumber==value)
                this.describe=ss[0].saleDescription
                this.pri=ss[0].price
                this.isC=ss[0].promotionClass
                this.code=ss[0].orderCode
            },
            getList(){

                let ss = this.$route.query.id
                // let ss= encrypt(JSON.stringify(obj))
                getProDetial(ss).then(res => {
                   let dd= JSON.parse(decrypt(res))

                    if (dd.code == 200) {
                      console.log(dd)
                         this.title=dd.data.productType
                         this.proList=dd.data.products
                         this.pri=dd.data.priceRange
                         this.imagePathP=dd.data.imagePath
                         this.describe=dd.data.saleDescription
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            addCar(){
                if(this.num ==''){
                    this.$message.error(`请选择商品`);
                }else {
                    this.btnLoading=true
                    let obj = {
                        "code": this.code,
                        "counts": this.counts
                    }
                    let ss= encrypt(JSON.stringify(obj))
                    addBuyCar(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))

                        if (dd.code == 200) {
                            this.$message.success(`加入成功`);
                            sessionStorage.setItem('carNum',dd.data)
                            // this.resetSetItem('carNum', dd.data);
                            this.$store.commit('updateCarNum',dd.data)
                            this.btnLoading=false
                        }else {
                            alert(dd.msg);
                            this.btnLoading=false
                        }

                    })
                }

            },

        }
    }
</script>

<style lang="less" scoped>
    .mdr_3{
        cursor: pointer;
        width: 165px;
        height: 50px;margin-left: 30px;
        border-radius: 5px;
        line-height: 50px;
        color: white;margin-top: 50px;
        text-align: center;font-size: 18px;
        background: url("../image/w_icon.png") 13px 13px rgba(32, 149, 238, 1) no-repeat;
        padding-left: 25px;
    }
    .mdr_4{
        cursor: pointer;
        width: 165px;
        height: 50px;
        margin-top: 50px;

        background: #EDEDED;
        border: 1px solid #333333;
        border-radius: 5px;
        line-height: 50px;
        color: #333333;
        text-align: center;font-size: 18px;
        background: url("../image/b_icon.png") 13px 13px rgba(237, 237, 237, 1) no-repeat;
        padding-left: 15px;
    }
    .md_main{
        background: white;
        height: 100%;
        display: flow-root;
        min-height: 529px;
        .md_top{
            width: 100%;
            background: #EEEEEE;
            height: 42px;
            border: 1px solid #E5E5E5;
            .md_top_son{
                width: 1200px;
                margin: 0px auto;
                font-size: 16px;
                color: #999999;
                line-height: 42px;

            }
        }
        .mdm_son{
            width: 1200px;
            margin: 0px auto;
            .mdm_l{
                float: left;
                margin-top: 20px;

              width: 500px;
              height: 500px;



              background-size:532px 500px ;
              background-position: center;
            }
            .mdm_r{
                float: left;
                width: 655px;
                .mdr_1{
                    font-size: 24px;
                    font-weight: bold;
                    color: #333333;
                    margin-top: 60px;
                }
                .mdr_2{
                    color: #999999;
                    font-size: 16px;
                    margin-top: 20px;
                    line-height: 30px;
                }
                .pdr_3{
                    margin-top: 36px;
                    /deep/ .el-radio{
                        margin-left: 0px;
                        margin-bottom: 10px;
                    }
                    .price{
                        color: red;
                        font-size: 26px;
                    }
                    span{
                        float: left;font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        height: 36px;
                        line-height: 36px;
                        margin-right: 45px;
                    }
                }
                .pdr_4{
                    width: 302px;
                    height: 42px;margin-top: 20px;
                    background: #EEEEEE;
                    color: #2095EE;font-size: 16px;
                    padding-left: 20px;
                    line-height: 42px;
                    border-left: 2px solid #2095EE;
                }
                .mdr_5{
                    margin-top: 36px;
                    height: 36px;
                    span{
                        float: left;font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        height: 36px;
                        line-height: 36px;
                        width: 100px;
                    }
                    .el-select,.el-input{
                        float: left;
                    }
                }
                .mdr_6{
                    margin-top: 60px;
                    margin-bottom: 60px;
                    width: 165px;
                    height: 50px;
                    background: #E72A32;
                    border-radius: 5px;font-size: 18px;

                }
            }
        }
    }
</style>
